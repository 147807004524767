import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo500.png";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

function Item({ icon, title }) {
  return (
    <div className="flex flex-row items-center gap-2">
      <div>{icon}</div>
      <div className="text-lg font-sans text-primary">{title}</div>
    </div>
  );
}

function Footer(props) {
  return (
    <div className="bg-secondary py-xl">
      <div className="grid lg:grid-cols-1 gap-x-8 gap-y-8 grid-flow-row-dense justify-center max-w-7xl mx-auto px-4 sm:px-6">
        <NavLink to="/" className="flex items-center justify-center">
          <img src={logo} alt="Logo" className="h-32" />
        </NavLink>

        <div>
          <div className="text-theme text-md font-bold drop-shadow-xl mb-2 text-center">
            Sosial Media
          </div>
          <div className="flex justify-between">
            <Item
              icon={<BsInstagram size={18} />}
              title={"PintuTogelOfficial"}
            />
            <Item icon={<BsFacebook size={18} />} title={"PintuTogel"} />
            <Item icon={<FaTiktok size={18} />} title={"PintuTogel"} />
          </div>
        </div>

        <div>
          <div className="text-theme text-md font-bold drop-shadow-xl mb-2 justify-center text-center">
            &copy; 2010-2023 • PINTUTOGEL &raquo; Lapak Bermain Togel Online
            Terbesar Di Indonesia
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
