import React from "react";
import singapore from "../assets/foto/singapore.jpg";
import hongkong from "../assets/foto/hongkong.jpg";
import macau from "../assets/foto/macau.jpg";
import { BsDpad } from "react-icons/bs";

const items = [
  {
    img: singapore,
    title: "Togel Singapore",
    bet: "100",
    type: "2D, 3D, 4D, Colok Bebas, Colok Jitu, Colok Naga",
  },
  {
    img: hongkong,
    title: "Togel Hong Kong",
    bet: "100",
    type: "4D, 3D, 2D, Colok Jitu, Colok Bebas, Colok Naga",
  },
  {
    img: macau,
    title: "Togel Macau",
    bet: "100",
    type: "3D, 4D, 2D, Colok Naga, Colok Jitu, Colok Bebas",
  },
];

function Item({ img, title, bet, type }) {
  return (
    <div className="w-full bg-primary shadow-lg border mx-auto rounded-md hover:scale-105">
      <img src={img} alt={title} width={"100%"} />
      <div className="p-4">
        <div className="text-2xl font-sans text-primary">{title}</div>
        <div className="text-theme font-bold drop-shadow-xl flex flex-row items-center">
          <div className="text-theme text-2xl font-bold drop-shadow-xl">
            Rp&nbsp;
          </div>
          <div className="text-theme text-2xl font-bold drop-shadow-xl">
            {bet}
          </div>
          <div className="text-theme text-md font-bold drop-shadow-xl">
            &nbsp;/ minimal bet
          </div>
        </div>
        <div className="text-primary font-bold drop-shadow-xl flex flex-row items-center py-2">
          <div className="text-sm font-bold drop-shadow-xl">{type}</div>
        </div>
        <div className="flex flex-row items-center gap-2 justify-center mt-4">
          <a
            href="rebrand.ly/pintulogin"
            target="_blank"
            rel="nofollow noopener"
            className="w-full flex flex-row items-center gap-2 bg-yellow-500 text-white py-2 px-4 hover:bg-yellow-600 shadow-md rounded"
          >
            <BsDpad />
            <div className="mx-auto">Main Sekarang</div>
          </a>
        </div>
      </div>
    </div>
  );
}

function Product(props) {
  return (
    <div className="bg-secondary mt-xl py-xl">
      <div className="grid lg:grid-cols-3 gap-x-4 gap-y-4 grid-flow-row-dense justify-center max-w-7xl mx-auto px-4 sm:px-6">
        {items.map((item, index) => (
          <Item
            key={index}
            img={item.img}
            title={item.title}
            bet={item.bet}
            type={item.type}
          />
        ))}
      </div>
    </div>
  );
}

export default Product;
