import React from "react";

function Item({ title, description }) {
  return (
    <div className="flex flex-row items-center gap-2 text-center">
      <div className="text-2xl font-sans text-theme">{title}</div>
      <div className="text-2xl font-sans text-primary">{description}</div>
    </div>
  );
}

function Fasilitas(props) {
  return (
    <div>
      <div className="text-theme text-2xl sm:text-4xl md:text-3xl md:text-4xl font-light drop-shadow-md tracking-wider py-lg lg:py-xl text-center">
        Informasi Layanan
      </div>
      <div className="grid lg:grid-cols-1 gap-x-2 gap-y-2 grid-flow-row-dense justify-between">
        <Item title="Situs" description="PintuTogel" />
        <Item title="Permainan" description="Togel Online" />
        <Item title="Minimal Deposit" description="Rp10.000" />
        <Item
          title="Pembayaran"
          description="Semua metode pembayaran Bank Transfer & E-Wallet"
        />
        <Item title="Jam Layanan" description="24 Jam" />
        <Item title="Pengguna" description="12,199,230 User" />
        <Item title="Tersedia di" description="Website, Android, iOS" />
        <Item
          title="Lisensi Situs"
          description="Lisensi Amusement And Gaming Corporation (PAGCOR), Lisensi Gaming Authority (MGA), Lisensi Gambling Commison Inggris"
        />
      </div>
    </div>
  );
}

export default Fasilitas;
