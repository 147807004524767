import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import logo from "../assets/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setShowLogo(!isMobileMenuOpen);
    if (isMobileMenuOpen && window.scrollY > 192) {
      setShowLogo(true);
    }
  };

  const isActiveLink = (path) => {
    return location.pathname === path ? "active-link" : "";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 192) {
        setShowLogo(true);
      } else {
        setShowLogo(false);
        if (isMobileMenuOpen === true) {
          setShowLogo(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-10 ${
        showLogo ? "show-logo" : ""
      }`}
    >
      <div className={`w-full mx-auto px-4 backdrop-filter backdrop-blur-md`}>
        <div className="flex justify-end items-center h-16 py-10 sm:px-6 lg:pl-4 w-full container">
          <NavLink to="/" className="flex items-center">
            {(showLogo || location.pathname !== "/") && (
              <motion.img
                src={logo}
                alt="Logo"
                className="h-12"
                initial={{ opacity: 0, y: 0, x: 150 }}
                animate={{ opacity: 1, y: 0, x: 150 }}
                transition={{ duration: 0.3 }}
                whileHover={{
                  rotate: [0, -10, 10, 0],
                  transition: { duration: 0.3 },
                }}
              />
            )}
            {/* <h1 className="text-theme font-sans p-sm">THE FINEST</h1> */}
          </NavLink>

          {/* Desktop Navigation Menu */}
          <div className="hidden md:flex w-full flex pl-11 justify-end">
            <NavLink
              to="/"
              className={`text-primary ${
                isActiveLink("/") ? "active-link" : "hover:text-themeDarker"
              }`}
            >
              Beranda
            </NavLink>
            <a
              href="https://rebrand.ly/pintulogin"
              className={`ml-4 text-primary ${
                isActiveLink("/login")
                  ? "active-link"
                  : "hover:text-themeDarker"
              }`}
              target="_blank"
              rel="noreferrer"
            >
              Login
            </a>
          </div>

          {/* Mobile Navigation Menu */}
          <div className="md:hidden flex items-center">
            {isMobileMenuOpen ? (
              <FiX
                className="text-primary text-2xl cursor-pointer"
                onClick={toggleMobileMenu}
              />
            ) : (
              <FiMenu
                className="text-primary text-2xl cursor-pointer"
                onClick={toggleMobileMenu}
              />
            )}
          </div>
        </div>

        {/* Mobile Drawer */}
        {isMobileMenuOpen && (
          <div className="md:hidden mt-2 pb-md">
            <NavLink
              to="/"
              className={`block py-2 text-primary pl-2 ${
                isActiveLink("/") ? "active-link" : "hover:text-themeDarker"
              }`}
            >
              Beranda
            </NavLink>

            <a
              href="https://rebrand.ly/pintulogin"
              className={`block py-2 text-primary pl-2 ${
                isActiveLink("/login")
                  ? "active-link"
                  : "hover:text-themeDarker"
              }`}
              target="_blank"
              rel="noreferrer"
            >
              Login
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
