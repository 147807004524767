import React from "react";
import { motion } from "framer-motion";
import logo from "../assets/logo500.png";
import Information from "../component/Information";
import Product from "../component/Product";
import Footer from "../component/Footer";
import Trend from "../component/Trend";

const HomePage = () => {
  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="bg-cover bg-center min-h-screen bg-primary">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-20">
        <div className="flex flex-col sm:flex-row-reverse items-center justify-center h-full gap-lg">
          <div className="sm:w-1/2 flex flex-wrap justify-center">
            <motion.img
              src={logo}
              alt="logo"
              className="max-h-32 rounded-md drop-shadow-lg relative lg:absolute mb-xl lg:mb-0 lg:left-[20%] block lg:hidden"
              initial={{ opacity: 0, y: -200 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              variants={imageVariants}
              whileHover={{
                rotate: [0, -10, 10, 0],
                transition: { duration: 0.3 },
              }}
            />
            <div>
              <div className="text-theme text-2xl sm:text-2xl md:text-5xl md:text-5xl uppercase font-bold drop-shadow-xl">
                PintuTogel
              </div>
              <p className="py-4 text-secondary tracking-wider text-justify">
                Terpercaya, Proses Cepat, Bonus Berlimpah, situs dengan fitur
                yang lengkap. Kami telah melayani jutaan transaksi lebih dari 10
                tahun. Terbukti memberikan pelayanan terbaik kepada pelanggan.
                Menjadi tempat bermain togel online paling disukai orang
                Indonesia.
              </p>
            </div>
          </div>

          <motion.h1
            className="lg:w-1/2 pt-8 lg:pr-8 lg:mt-auto"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <motion.img
              src={logo}
              alt="logo"
              className="max-h-[200px] rounded-md drop-shadow-lg hidden lg:block mx-auto mb-xl"
            />
          </motion.h1>
        </div>

        <Information />
      </div>
      <Product />
      <Trend />

      {/* DIVIDER */}
      <Footer />
    </div>
  );
};

export default HomePage;
